import React from 'react';
import './Loader.css'; // Make sure you reference your CSS file

const Loader = () => (
  <div className="loader-container">
    {/* Use the imported image as a loader */}
    <img src={require('../../assets/WEDP/loder/trophy (1).webp')} alt="Loading..." />
  </div>
);

export default Loader;
