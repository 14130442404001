// src/contexts/ProfileContext.js
import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";
import apiService, { getToken } from "../services/apiService";
const placetoken = getToken();
export const ProfileContext = createContext();
export const useProfile = () => useContext(ProfileContext);
export const ProfileProvider = ({ children }) => {
  const [places, setPlaces] = useState(null);
  const [IsPlacesloading, setIsPlacesloading] = useState(false);
  const [placesError, setPlacesError] = useState(null);
  const [profilePicture, setProfilePicture] = useState(null);

  // States for Notifications
  const [Notifications, setNotifications] = useState(null);
  const [isNotificationsLoading, setIsNotificationsLoading] = useState(false);
  const [NotificationsError, setNotificationsError] = useState(null);

  const [isupdated, setIsUpdated] = useState(false);
  const [idstatus, setIdstatus] = useState(null);
  const [Isidstatusloading, setIsidstatusloading] = useState(false);
  const [idStatusError, setIdStatusError] = useState(null);

  // States for Matches
  // const [matches, setMatches] = useState(null);
  // const [isMatchesLoading, setIsMatchesLoading] = useState(false);
  // const [MatchesError, setMatchesError] = useState(null);
  // Sound-related states
  // Sound and Interaction States
  const [sound, setSound] = useState(null);
  const [showVisualAlert, setShowVisualAlert] = useState(false);
  const [soundEnabled, setSoundEnabled] = useState(false); // User enables sound

  // Initialize sound only after user interaction
  const enableSound = () => {
    const newSound = new Audio(require("../assets/sound/sound6.mp3"));
    setSound(newSound);
    setSoundEnabled(true); // Sound is now enabled
  };



  //places fnuctions
  const fetchPlaces = useCallback(async () => {
    // if (!placetoken) return; // Exit early if there's no placetoken
    setIsPlacesloading(true);
    setPlacesError(null);

    try {
      const placeResponse = await apiService.listPlaces();
      if (placeResponse && placeResponse.data.length > 0) {
        const firstPlace = placeResponse.data[0];
        setProfilePicture(firstPlace.profile_picture_urls?.[0] || null);
        setPlaces(placeResponse.data);
      }
    } catch (error) {
      console.error("Failed to fetch places", error);
      setPlacesError(error.message || "Failed to fetch places");
    } finally {
      setIsPlacesloading(false);
    }
  }, []);
  const updatePlaces = async (updatedPlaces) => {
    setIsUpdated(true);
    try {
      await apiService.updatePlace(updatedPlaces);
      fetchPlaces();
      //   setPlaces(response.data);
    } catch (error) {
      console.error("Error updating places:", error);
    } finally {
      setIsUpdated(false);
    }
  };

  //ID function
  const fetchStatusid = useCallback(async () => {
    // if (!placetoken) return; // Exit early if there's no placetoken

    setIsidstatusloading(true);
    setIdStatusError(null);
    try {
      const response = await apiService.getStatusById();
      setIdstatus(
        response.status === 204
          ? { status: "Missing" }
          : response.status === 200
          ? response.data
          : null
      );
    } catch (error) {
      setIdStatusError(error || "Failed to fetch status ID");
      console.error("Error fetching Status ID data:", error);
    } finally {
      setIsidstatusloading(false);
    }
  }, []);

  // // Fetch Matches
  // const fetchMatches = useCallback(async () => {

  //   if (!placetoken) return;

  //   setIsMatchesLoading(true);
  //   setMatchesError(null);
  //   try {
  //     const response = await apiService.getMatchesByPlayerId();
  //     setMatches(response.data || []);
  //   } catch (error) {
  //     setMatchesError(error.message || "Failed to fetch Matches");
  //   } finally {
  //     setIsMatchesLoading(false);
  //   }
  // }, []);

  // Fetch Notification
  const fetchNotifications = useCallback(async () => {
    if (!placetoken) return;

    setIsNotificationsLoading(true);
    setNotificationsError(null);
        const playSound = () => {
          if (soundEnabled && sound) {
            sound
              .play()
              .catch((error) => console.error("Playback failed:", error));
          }
        };

    try {
      const response = await apiService.notifications(1, 10); // Simulating fetching notifications from an API
      if (response.hasnew) {
        playSound();
        setShowVisualAlert(true); // Show visual notification for accessibility
      }
      setNotifications(response || []);
    } catch (error) {
      setNotificationsError(error.message || "Failed to fetch Notification");
    } finally {
      setIsNotificationsLoading(false);
    }
  }, [sound, soundEnabled]);
  useEffect(() => {
    if (placetoken) {
      if (!places && !placesError && !IsPlacesloading) {
        fetchPlaces();
      }
      if (!idstatus && !idStatusError && !Isidstatusloading) {
        fetchStatusid();
      }

      // if (!matches && !MatchesError && !isMatchesLoading) {
      //   console.log("Fetching matches");
      //   fetchMatches();
      // } else {
      //   console.log(matches, MatchesError, "else");
      // }

      if (!Notifications && !NotificationsError && !isNotificationsLoading) {
        console.log("Fetching Notifications");
        fetchNotifications();
      } else {
        console.log(Notifications, NotificationsError, "else");
      }
    }
  }, [
    fetchPlaces,
    fetchStatusid,
    idstatus,
    idStatusError,
    places,
    placesError,
    IsPlacesloading,
    Isidstatusloading,
    Notifications,
    NotificationsError,
    isNotificationsLoading,
    fetchNotifications,
  ]);

  const refreshPlacesData = useCallback(() => {
    fetchPlaces();
    fetchStatusid();
  }, [fetchPlaces, fetchStatusid]);
  const logoutPlaces = useCallback(() => {
    setPlaces(null);
    setIdstatus(null);
    // setMatches(null);
    setNotifications(null);
  }, []);
  return (
    <ProfileContext.Provider
      value={{
        places,
        fetchPlaces,
        updatePlaces,
        IsPlacesloading,
        isupdated,
        fetchStatusid,
        idstatus,
        Isidstatusloading,
        setIsidstatusloading,
        logoutPlaces,
        placesError,
        idStatusError,
        refreshPlacesData,
        // matches,
        // isMatchesLoading,
        // MatchesError,
        Notifications,
        isNotificationsLoading,
        NotificationsError,

        // Expose this function to trigger fetching when needed
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};
