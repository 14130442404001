// src/contexts/AuthContext.js
import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import apiService from "../services/apiService";
import { jwtDecode } from "jwt-decode";
import { useProfile } from "../context/ProfileContext";

export const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
  const getToken = () => localStorage.getItem("placetoken");
  const isTokenExpired = () => {
    const token = getToken();
    if (!token) return true;

    try {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Get current time in seconds
      if (decoded.exp < currentTime) {
        // Token is expired
        return true;
      }
      return false;
    } catch (error) {
      // If there's any error decoding the token, consider it invalid/expired
      return true;
    }
  };
  const getUserIdFromToken = useCallback(() => {
    const placetoken = getToken();
    if (placetoken) {
      const decoded = jwtDecode(placetoken);
      // Check token expiry
      if (isTokenExpired) {
        return null; // Token has expired
      }
      return (
        decoded.placesauthId ||
        decoded.adminId ||
        decoded.playerId ||
        decoded.partnerId
      ); // Adjust the key based on your token payload structure
    }
    return null;
  }, []);
  const [auth, setAuth] = useState({
    placetoken: localStorage.getItem("placetoken") || "",
    first_name: localStorage.getItem("first_name") || "",
    placeemail: localStorage.getItem("placeemail") || "",
    isAuthenticated: !isTokenExpired(),
    userId: getUserIdFromToken(),
  });

  const { refreshPlacesData } = useProfile();

  useEffect(() => {
    const fetchUserData = async () => {
      if (!auth.placetoken) return;
      apiService.setAuthToken(auth.placetoken);
      auth.userId = getUserIdFromToken();
    };

    fetchUserData();
  }, [auth, getUserIdFromToken]);
  const login = useCallback(
    (placetoken, first_name,placeemail) => {
      localStorage.setItem("placetoken", placetoken);
      localStorage.setItem("first_name", first_name);
      localStorage.setItem("placeemail", placeemail);
      setAuth({
        placetoken: placetoken,
        first_name: first_name,
        placeemail:placeemail,
        isAuthenticated: true,
        userId: getUserIdFromToken(),
      });
      apiService.setAuthToken(placetoken);
      console.log(auth,first_name)
      refreshPlacesData();
    },
    [auth, getUserIdFromToken, refreshPlacesData]
  );

  const logout = useCallback(async () => {
    localStorage.removeItem("placetoken");
    localStorage.removeItem("first_name");
    localStorage.removeItem("placeemail");
    setAuth({
      placetoken: "",
      first_name: "",
      placeemail:"",
      isAuthenticated: false,
      userId: null,
    });
    apiService.setAuthToken(null);
    await apiService.logout();
    // Reset notifications on logout
    // setNotifications(defaultNotifications);
  }, []);

  const contextValue = useMemo(
    () => ({
      auth,
      login,
      logout,
      // notifications,
      // addNotification,
    }),
    [auth, login, logout]
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export default React.memo(AuthProvider);
